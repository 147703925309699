<template>
  <div>
    <!-- custom Html Start-->
    <div class="sectionOneBoxWrap">
      <div class="insideItemBoxWrap row">
        <div
          v-if="loading"
          class=" d-flex justify-space-between"
        >
          <v-skeleton-loader
            v-for=" n in 7"
            :key="n"
            type="card"
            class="ma-2"
            width="200px"
            height="150px"
          />
        </div>
        <DashboardSlider
          v-else
          :items="dashboardSort?.section_1"
          :options="options"
          :vehicle-tech="vehicleTech"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardSlider: () => import("../utils/DashboardSlider.vue"),
  },

  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      isActiveModal: false,
      icons: {
        total_avg_range: "mdi-car-speed-limiter",
        total_avg_speed: "mdi-speedometer",
        average_mileage: "mdi-road-variant",
        avg_efficincy_in_pkr_km: "mdi-map-marker-distance",
        avg_efficincy_in_pkr_kg: "mdi-weight",
        avg_time_to_charge: "mdi-ev-station",
        upcoming_maintenance: "mdi-cog",
        average_soc: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      dashboardSort: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
      dashboadSortData: "dashboard/getDashboadSortData",
    }),
    sectionOne() {
      return this.dashboardSort?.section_1;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
  },
  methods: {

    getCardName(name) {
      return name.toLowerCase().replace(/\s+/g, '-');
    },

    getIcon(key) {
      return this.icons[key] || "mdi-help";
    },

    getCardColor(key) {
      if (key == "avg_efficincy_in_pkr_kg") {
        return "defaultbg"
      } else if (key == "average_mileage") {
        return "defaultbg"
      } else if (key == "avg_efficincy_in_pkr_km") {
        return "defaultbg"
      } else if (key == "total_avg_speed") {
        return "defaultbg"
      } else if (key == "total_avg_range") {
        return "defaultbg"
      } else if (key == "upcoming_maintenance") {
        return "defaultbg"
      } else if (key == "avg_time_to_charge") {
        return "defaultbg"
      }

    },

    //
    goToTelematics(val) {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      }
      else {
        params.tenant_id = this.options.tenant_id;
      }
      if (val == "active_vehicles") {
        params.status = "active";
      } else if (val == "in_active_vehicles") {
        params.status = "inactive";
      } else if (val == "offline_vehicles") {
        params.status = "offline";
      }

      return this.$router.push({
        name: "vehicle_telematics",
        query: params,
      });
    },
    openActiveModal() {
      this.isActiveModal = true;
    },

  },
};
</script>
<style lang="sass" scoped>
$primaryColor : #23BDAA

.section-one-in-active
  .box
    cursor: auto !important

.section-one
    display: flex
    flex: 1
    gap: 1.7rem
    flex-wrap:    wrap
    height: fit-content
    width: 100%
    .box
        cursor: all-scroll
        user-select: none
        height: 170px
        background: #d5f1d5 !important
        // min-width: 230px
        flex-basis: 15%
        margin: 0px
        &:hover
            box-shadow: 2px 1px 8px #20a390

        .box-wrapper
            height: inherit
            padding: 1rem 0.5rem
            .header
                display: flex
                justify-content: space-between
                align-items: center
                .title
                    font-weight: 600
                    font-size: 14px !important
                .icon
                    width: 40px
                    height: 40px
                    display: flex
                    justify-content: center
                    align-items: center
                    border-radius: 100%
                    background: $primaryColor
                    i
                        color: white
                        font-size: 2rem


            .content
                div
                    display: flex
                    justify-content: space-between
                    align-items: center
                .title
                    font-weight: 600
                    font-size: 14px !important
                .value
                    font-weight: 600
                    font-size: 16px

                .SI-Units
                    font-size: 11px

            .single-value, .multi-value
                height: -webkit-fill-available
                display: flex
                flex-direction: column
                justify-content: space-between

            .multi-value
                .content
                    div
                        display: block
                    .content-line
                        display: flex
                        justify-content: space-between
                        .title
                            font-weight: normal
                            font-size: 12px !important


.Vehicle-Status
    i
        margin-right: 0.5rem
        font-size: 12px

    .active_vehicles
        i, .value
            color: #4CAF50 !important

    .in_active_vehicles
        i, .value
            color: #f0ae38 !important

    .offline_vehicles
        i, .value
            color: #ff0000 !important

.active
    .content-line
        cursor: pointer
        &:hover
            text-decoration: underline
            font-style: italic



@media only screen and (max-width: 960px)
  .box
    flex-basis: 100% !important



</style>